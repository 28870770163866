export const capitalizeWords = (input: string): string => {
    return input.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const formatZipCode = (input: string): string => {
    // Remove all non-alphanumeric characters, except spaces to preserve user-inserted spaces during typing
    let cleanInput = input.replace(/[^0-9a-zA-Z ]/g, '');

    // Extract the first four digits
    let digits = cleanInput.slice(0, 4).replace(/[^\d]/g, '');

    // Extract up to two letters, skipping over spaces just after the digits
    let letters = cleanInput.slice(4).trim().replace(/[^a-zA-Z]/g, '').slice(0, 2).toUpperCase();

    // Combine digits and letters with a space in between when the four digits are complete and letters are starting
    if (digits.length === 4 && (letters.length > 0 || cleanInput.length > 4)) {
        return `${digits} ${letters}`;
    } else {
        return digits;
    }
};
